import ToggleNav from './toggle-nav.js';

class Quickview extends ToggleNav {
  constructor() {
    super();
  }

  init() {
    this.addEventListener('click', this.onClick.bind(this), false);
    this.addEventListener(
      'keypress',
      function (event) {
        if (event.key === 'Enter') {
          this.onClick.bind(this)(event);
          const cartNotification =
            document.querySelector('cart-notification') || document.querySelector('cart-drawer');
          if (cartNotification) cartNotification.setActiveElement(this);
        }
      }.bind(this),
      false
    );
  }

  onClick(e) {
    e.preventDefault();
    const quickbuyCanvas = document.querySelector('quickbuy-canvas');
    if (quickbuyCanvas) quickbuyCanvas.setActiveElement(this);
    this.quickview();
  }

  quickview() {
    if (this.dataset.shopableImage) {
      if (window.innerWidth < 768) {
        this.fetchUrl();
      }
    } else {
      this.fetchUrl();
    }
    this.setAttribute('aria-disabled', true);
    this.classList.add('loading');
    this.querySelector('.loading-overlay__spinner')?.classList.remove('hidden');
  }

  fetchUrl() {
    fetch(this.dataset.url)
      .then((response) => response.text())
      .then((text) => {
        const html = parser.parseFromString(text, 'text/html');
        if (!html.querySelector('#shopify-section-product-quickview .quickview-content')) return;
        document.querySelector('.quick_shop-popup').innerHTML = html.querySelector(
          '#shopify-section-product-quickview .quickview-content'
        ).innerHTML;
      })
      .finally(() => {
        this.classList.remove('loading');
        this.removeAttribute('aria-disabled');
        this.querySelector('.loading-overlay__spinner')?.classList.add('hidden');
        this.onMainClick();
        if (window.SPR) {
          window.SPR.registerCallbacks();
          window.SPR.initRatingHandler();
          window.SPR.initDomEls();
          window.SPR.loadProducts();
          window.SPR.loadBadges();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

export default Quickview;
