import { getScrollBarWidth } from './theme.js';

export const root = document.getElementsByTagName('html')[0];

export const rootAction = (function () {
  return {
    add: function () {
      if (!root) return;
      root.style.setProperty('--padding-right', getScrollBarWidth.init() + 'px');
    },
    addNoneHeight: function () {
      if (!root) return;
      root.style.setProperty('--padding-right', getScrollBarWidth.init() + 'px');
    },
    remove: function () {
      if (!root) return;
      root.style.removeProperty('--padding-right');
      root.classList.remove('open-canvas');
      root.classList.remove('open-search');
      root.classList.remove('open-nav');
    },
    toggle: function () {
      if (!root) return;
      if (getComputedStyle(root).getPropertyValue('--padding-right')) {
        root.style.removeProperty('--padding-right');
      } else {
        root.style.setProperty('--padding-right', getScrollBarWidth.init() + 'px');
      }
      root.classList.toggle('open-canvas');
      root.classList.toggle('open-search');
      root.classList.toggle('open-nav');
    },
  };
})();
