import { root, rootAction } from './root.js';
class QuickbuyCanvas extends HTMLElement {
  constructor() {
    super();
    this.addEventListener(
      'keyup',
      (event) => event.code.toUpperCase() === 'ESCAPE' && this.close()
    );
    this.closeBtn = this.querySelector('close-button');
    if (!this.closeBtn) return;
    this.closeBtn.addEventListener('click', this.close.bind(this));
    this.addEventListener(
      'keypress',
      function (event) {
        if (event.key === 'Enter') {
          const focusedElement = document.activeElement;
          if (focusedElement && focusedElement.tagName === 'CLOSE-BUTTON') {
            this.close.bind(this)(event);
          }
        }
      }.bind(this),
      false
    );
  }

  close() {
    rootAction.remove();
    this.classList.remove('active');
    removeTrapFocus(this.activeElement);
  }

  setActiveElement(element) {
    this.activeElement = element;
  }
}

export default QuickbuyCanvas;
