import { closePopup, getScrollBarWidth } from './theme.js';
import { trapFocus } from './global.js';
import { root, rootAction } from './root.js';
import Close from './close-button.js';

class ToggleNav extends HTMLElement {
  constructor() {
    super();
    this.init();
  }
  init() {
    const item = this.dataset.item;
    if (this.classList.contains('toggle-action')) {
      const parent = this.closest('.overlay-custom-popup');
      const content = document.createElement('div');
      content.appendChild(
        parent.querySelector('template').content.firstElementChild.cloneNode(true)
      );
      if (content.querySelector('.content-items') && document.querySelector('.' + item)) {
        document.querySelector('.' + item).innerHTML =
          content.querySelector('.content-items').innerHTML;
      }
    }
    this.addEventListener('click', this.onClick.bind(this), false);
    this.addEventListener(
      'keypress',
      function (event) {
        if (event.key === 'Enter') {
          this.onClick.bind(this)(event);
        }
      }.bind(this),
      false
    );
  }
  onClick(e) {
    e.preventDefault();
    this.onMainClick();
  }
  onMainClick() {
    const _this = this;
    closePopup.hide();
    rootAction.addNoneHeight();
    const os = document.querySelectorAll('.overlay-section');
    const item = this.dataset.item;
    const overlaySection = document.querySelector(`.${item}`);
    if (overlaySection) {
      setTimeout(() => {
        trapFocus(overlaySection.querySelector('.bls__canvas'));
      }, 500);
    }
    if (os.length > 0) {
      os.forEach(function (e) {
        if (e.classList.contains(item)) {
          if (e.querySelector('.bls__canvas')) {
            e.querySelector('.bls__canvas').classList.add('active');
            root.classList.add('open-canvas');
            Close.init;
          }
        }
      });
    }
    if (item === 'overlay-filter') {
      const details_tag = _this.closest('details');
      if (details_tag) {
        details_tag.setAttribute('open', '');
      }
    }
  }
  onToggle() {
    const _this = this;
    const os = document.querySelectorAll('.overlay-section');
    if (getComputedStyle(root).getPropertyValue('--padding-right')) {
      root.style.removeProperty('--padding-right');
    } else {
      root.style.setProperty('--padding-right', getScrollBarWidth.init() + 'px');
    }
    const item = this.dataset.item;
    if (os.length > 0) {
      os.forEach(function (e) {
        if (e.classList.contains(item)) {
          if (e.querySelector('.bls__canvas')) {
            if (_this.classList.contains('toggle-action')) {
              e.querySelector('.bls__canvas').classList.toggle('active');
              root.classList.toggle('open-canvas');
            }
            Close.init;
          }
        }
      });
    }
  }
}

export default ToggleNav;
