import { trapFocus, removeTrapFocus } from './global.js';
import { root } from './root.js';

class ToggleMenu extends HTMLElement {
  constructor() {
    super();
    this.container = this.closest('.overlay-section.overlay-menu');
    this.button = this.querySelector('button#menu-bar-icon-bubble');
    this.mainMenu = this.container.querySelector('main-menu');
    this.init();
  }

  init() {
    this.addEventListener('click', this.onClick.bind(this), false);
    this.container.addEventListener(
      'keyup',
      (event) => event.code.toUpperCase() === 'ESCAPE' && this.close()
    );
  }

  onClick(e) {
    e.preventDefault();

    if (root.classList.contains('open-nav')) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    if (this.container && this.button) {
      trapFocus(this.container, this.button);
    }
    root.style.removeProperty('--padding-right');
    root.classList.remove('open-canvas');
    root.classList.remove('open-search');
    root.classList.add('open-nav');
  }

  close(focusToggle = true) {
    removeTrapFocus(focusToggle ? this.button : null);
    root.classList.remove('open-nav');
  }
}

export default ToggleMenu;
