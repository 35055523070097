import { closePopup, slideAnime } from './theme.js';

class CollapsibleSection extends HTMLElement {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
    this.init();
    window.addEventListener('resize', this.init.bind(this));
  }

  init() {
    if (!this.querySelector('.collapsible-title')) return;
    if (window.innerWidth < 768) {
      this.querySelector('.collapsible-title').addEventListener('click', this.onClick, false);
      this.addEventListener('keypress', this.keyPressHandler, false);
    } else {
      this.querySelector('.collapsible-title').removeEventListener('click', this.onClick, false);
      this.removeEventListener('keypress', this.keyPressHandler, false);
    }
  }

  onClick(e) {
    e.preventDefault();
    if (!this.querySelector('.footer-block-border')) return;
    if (!this.querySelector('.collapsible-content')) return;
    this.querySelector('.footer-block-border').classList.toggle('active');
    slideAnime({
      target: this.querySelector('.collapsible-content'),
      animeType: 'slideToggle',
      parent: this.querySelector('.collapsible-title'),
    });
  }

  keyPressHandler(event) {
    if (event.key === 'Enter') {
      this.onClick(event);
    }
  }
}

export default CollapsibleSection;
