import { trapFocus } from './global.js';
import Popup from './custom-popup.js';

class ModalPopup extends Popup {
  constructor() {
    super();
    this.init();
  }

  init() {
    const title = this.dataset?.title;
    const id = this.dataset?.id;
    const btn = this.querySelector('.modal-btn');
    if (!btn) return;
    btn.addEventListener('click', () => {
      let content = this.querySelector('.content');
      if (content) {
        this.initPopupJs(content.innerHTML, title, id, true, false);
        setTimeout(() => {
          if (document.querySelector(`#${id}_0_1`) && id != 'ask-question') {
            trapFocus(document.querySelector(`#${id}_0_1`), document.querySelector('.dlg-close-x'));
          }
        });
      }
    });
  }
}

export default ModalPopup;
