import Close from './close-button';
import { root, rootAction } from './root.js';

class Overlay extends Close {
  constructor() {
    super();
  }
  onClose(e) {
    e.preventDefault();
    rootAction.remove();
    const os = document.querySelectorAll('.overlay-section');
    if (os.length > 0) {
      os.forEach(function (e) {
        const divCanvas = e.querySelector('.bls__canvas') || e.querySelector('.main-menu');
        if (divCanvas) {
          divCanvas.classList.remove('active');
          divCanvas.removeAttribute('open');
          const details_tag = divCanvas.closest('details');
          if (details_tag && !details_tag.classList.contains('has-open')) {
            setTimeout(() => {
              details_tag.removeAttribute('open');
            }, 300);
          }
        }
      });
    }
  }
}

export default Overlay;
