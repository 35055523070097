import BaseSlide from './base-slide.js';
import { checkDeviceIsMobile } from './theme.js';

class SlideSection extends BaseSlide {
  constructor() {
    super();
    this.init();
  }

  init() {
    this.initSlide();
    const _this = this;
    const has_item_mb = this.dataset?.freeScroll;
    if (has_item_mb) {
      if (window.innerWidth <= 767) {
        _this.destroy();
        _this.slider = null;
        _this.initProgressBar();
      } else {
        _this.destroy();
        _this.slider = null;
        _this.initSlide();
      }
    }
    const noResize =
      this.classList.contains('slide-section-tiktok') ||
      this.classList.contains('section-slide-product-media');
    if (!noResize) {
      this.onResize();
    }
    if (this.classList.contains('slide-section-slideshow')) {
      _this.lazyloadImage();
    }
  }

  onResize() {
    const _this = this;
    const has_item_mb = this.dataset?.freeScroll;
    let windowWidth = window.innerWidth;
    let isMobile = windowWidth <= 767;
    window.addEventListener('resize', function () {
      const newWindowWidth = window.innerWidth;
      const newIsMobile = newWindowWidth <= 767;
      _this.refresh();
      _this.onSlideChange();
      if (newIsMobile !== isMobile) {
        windowWidth = newWindowWidth;
        isMobile = newIsMobile;
        if (has_item_mb) {
          if (isMobile) {
            _this.destroy();
            _this.slider = null;
            _this.initProgressBar();
          } else {
            _this.removeProgressBar();
            _this.destroy();
            _this.slider = null;
            _this.initSlide();
          }
        }
        _this.customForMediaGallery(isMobile);
      }
    });
    _this.customForMediaGallery(isMobile);
  }

  customForMediaGallery(isMobile) {
    const isThumb = this.classList.contains('slide-section-thumbnail');
    if (!isThumb) return;
    const mediaGallery = this.closest("media-gallery[data-media-type='thumbnail_slider']");
    if (mediaGallery) {
      const sl_thumb = this.closest('.slide-thumbnail');
      const mobileSlideStatus = mediaGallery.dataset.mobiSlideStatus;
      const position = mediaGallery.dataset.slidePosition;

      if (sl_thumb) {
        if (mobileSlideStatus == 'false') {
          if (isMobile) {
            sl_thumb.classList.add('d-none');
          } else {
            sl_thumb.classList.remove('d-none');
          }
        } else {
          if (isMobile != false) {
            mediaGallery.classList.remove('thumbnail-position-left');
            mediaGallery.classList.add('thumbnail-position-bottom', 'column-reverse');
            this.setAttribute('data-axis', 'horizontal');
            this.refresh();
          } else {
            sl_thumb.classList.remove('d-none');
            if (position && position == 'vertical') {
              mediaGallery.classList.add('thumbnail-position-left');
              mediaGallery.classList.remove('thumbnail-position-bottom', 'column-reverse');
              this.setAttribute('data-axis', 'vertical');
              this.refresh();
            }
          }
        }
      }
    }
  }

  initProgressBar() {
    if (!this.querySelector('.tns-progress')) {
      var progressBarContainer = document.createElement('div');
      progressBarContainer.classList.add('tns-progress');
      var progressBar = document.createElement('div');
      progressBar.classList.add('tns-progress-bar');
      progressBar.style.width = '5%';
      progressBarContainer.appendChild(progressBar);
      this.appendChild(progressBarContainer);
      setTimeout(() => {
        this.actionScrollBar();
      });
    }
  }
  removeProgressBar() {
    if (this.querySelector('.tns-progress')) {
      this.querySelector('.tns-progress').remove();
    }
  }

  actionScrollBar() {
    var container = this.querySelector('.slide-container');
    if (!container) return;
    var progressBar = this.querySelector('.tns-progress-bar');
    if (!progressBar) return;
    container.addEventListener('scroll', () => {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const scrollPercentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;
      if (scrollPercentage <= 100) {
        progressBar.style.width = `${scrollPercentage}%`;
      }
      if (scrollPercentage <= 5) {
        progressBar.style.width = `5%`;
      }
    });
  }
  gotoFunction(item) {
    this.slider.goTo(item);
  }
  addClass(item, custom_class) {
    if (this.slider.getInfo().container) {
      if (this.slider.getInfo().container.querySelectorAll('.tns-item').length == 0) return;
      this.slider
        .getInfo()
        .container.querySelectorAll('.tns-item')
        .forEach((e) => {
          e.classList.remove(custom_class);
          const order = e.dataset.order;
          if (order && order === item) {
            e.classList.add(custom_class);
          }
        });
    }
  }
  onSlideChange() {
    const _this = this;
    const s = this.slider;
    if (!s) return;
    if (checkDeviceIsMobile()) {
      s.events.on('touchEnd', _this.customFunction.bind(s));
    } else {
      s.events.on('dragEnd', _this.customFunction.bind(s));
    }
    if (s.getInfo().container) {
      const next = s
        .getInfo()
        .container.closest('slide-section')
        ?.querySelector(".tns-controls>button[data-controls='next']");
      const prev = s
        .getInfo()
        .container.closest('slide-section')
        ?.querySelector(".tns-controls>button[data-controls='prev']");
      if (next) {
        next.addEventListener('click', function () {
          setTimeout(() => {
            _this.customFunction(s.getInfo());
          });
        });
      }
      if (prev) {
        prev.addEventListener('click', function () {
          setTimeout(() => {
            _this.customFunction(s.getInfo());
          });
        });
      }
    }
  }
  customFunction(s) {
    if (!s.container) return;
    if (s.container.closest('media-gallery')) {
      const thumb = s.container
        .closest('media-gallery')
        .querySelector('slide-section.slide-section-thumbnail');
      if (s.container.querySelector('.tns-slide-active') && thumb) {
        thumb.gotoFunction(s.container.querySelector('.tns-slide-active').dataset.position - 1);
      }
    }
  }
  refresh() {
    const item3d = this.querySelector('.shopify-model-viewer-ui');
    if (item3d) return;
    if (!this.slider || this.classList.contains('slide-section-slideshow')) return;
    this.slider.destroy();
    this.slider = null;
    this.initSlide();
  }
  destroy() {
    if (!this.slider) return;
    this.slider.destroy();
    this.slider = null;
  }
  rebuild() {
    if (!this.slider) return;
    this.slider.rebuild();
  }
  lazyloadImage() {
    if (this.querySelectorAll('.loading-animation').length != 0) {
      this.querySelectorAll('.loading-animation').forEach((e, index) => {
        var img = new Image();
        if (e.getAttribute('src')) {
          img.src = e.getAttribute('src');
          img.addEventListener('load', function () {
            setTimeout(() => {
              e.classList.add('loaded-animation');
              e.classList.remove('loading-animation');
            }, 100);
          });
        } else {
          setTimeout(() => {
            e.classList.add('loaded-animation');
            e.classList.remove('loading-animation');
          }, 200);
        }
      });
    }
  }
}

export default SlideSection;
