import { root, rootAction } from './root.js';

class Close extends HTMLElement {
  constructor() {
    super();
    this.init();
  }
  init() {
    const _this = this;
    this.addEventListener('click', this.onClose.bind(this), false);
    this.addEventListener(
      'keypress',
      function (event) {
        if (event.key === 'Enter') {
          const divParent = _this.closest('.bls__canvas');
          if (divParent) {
            if (!divParent.classList.contains('quick_shop-popup')) {
              if (divParent.classList.contains('cart-drawer')) {
                const elementToFocus = divParent.dataset.focusItem;
                removeTrapFocus(document.querySelector('#' + elementToFocus));
                divParent.close();
              } else {
                const elementToFocus = divParent.dataset.focusItem;
                removeTrapFocus(document.querySelector('#' + elementToFocus));
                this.onClose.bind(this)(event);
              }
            }
          } else {
            this.onClose.bind(this)(event);
          }
        }
      }.bind(this),
      false
    );
  }
  onClose(e) {
    e.preventDefault();
    const cb = e.currentTarget.closest('.has-close-btn');
    if (!cb) return;
    if (cb.classList.contains('close-slide-up')) {
      rootAction.remove();
      const os = document.querySelectorAll('.overlay-section');
      if (os.length > 0) {
        os.forEach(function (e) {
          const divCanvas = e.querySelector('.bls__canvas') || e.querySelector('.main-menu');
          if (divCanvas) {
            divCanvas.classList.remove('active');
            divCanvas.removeAttribute('open');
            const details_tag = divCanvas.closest('details');
            if (details_tag && !details_tag.classList.contains('has-open')) {
              setTimeout(() => {
                details_tag.removeAttribute('open');
              }, 300);
            }
          }
        });
      }
    } else {
      cb.remove();
    }
  }
}

export default Close;
